import React, { useState } from "react";

import PageWrapper from "WebVisual/PageWrapper";
import ArticleCard from "WebVisual/Components/ArticleCard";
import ChatWidget from "WebVisual/Components/ChatWidget";
import { useGetLastChatInfo } from "WebVisual/Components/ChatWidget/hooks";

const Home = () => {
  const [search, setSearch] = useState("");

  const { lastCreatedChat, requesting } = useGetLastChatInfo();

  return (
    <PageWrapper
      onChange={setSearch}
      title="Home"
      pageBodyHeader={<ChatWidget lastCreatedChat={lastCreatedChat} requesting={requesting} />}
    >
      <ArticleCard search={search} />
    </PageWrapper>
  );
};

export default Home;
