import React, { useState } from "react";

import PageWrapper from "WebVisual/PageWrapper";
import PodcastList from "WebVisual/Components/PodcastList";
import ChatWidget from "WebVisual/Components/ChatWidget";
import { useGetLastChatInfo } from "WebVisual/Components/ChatWidget/hooks";

const Podcast = () => {
  const [search, setSearch] = useState("");

  const { lastCreatedChat, requesting } = useGetLastChatInfo();

  return (
    <PageWrapper
      onChange={setSearch}
      title="Podcast"
      pageBodyHeader={
        lastCreatedChat ? <ChatWidget lastCreatedChat={lastCreatedChat} requesting={requesting} /> : undefined
      }
    >
      <PodcastList search={search} />
    </PageWrapper>
  );
};

export default Podcast;
