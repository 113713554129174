import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { constants } from "../redux";

export const useGetLastChatInfo = () => {
  const dispatch = useDispatch();

  const state = useSelector((state) => state?.VisualChatWidgetReducer);

  const lastCreatedChat = state.last_created_chat;
  const requesting = state.requesting;

  useEffect(() => {
    dispatch({
      type: constants.GET_LAST_CREATED_CHAT,
    });
  }, [dispatch]);

  return {
    lastCreatedChat,
    requesting,
  };
};
