import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ChatContinueConversationState, ChatWelcomeState } from "./components";
import { constants as chatConstants } from "../Chat/redux";

import "./style.scss";

const ChatWidget = ({ lastCreatedChat, requesting }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleChatOpen = useCallback(() => {
    history.push(`/user/chat/${lastCreatedChat?.id}`);
  }, [lastCreatedChat]);

  const handleStartConversation = useCallback(
    (message) => {
      dispatch({
        type: chatConstants.CREATE_CHAT,
        cbSuccess: (chat) =>
          history.push({
            pathname: `/user/chat/${chat.id}`,
            state: { message },
          }),
      });
    },
    [dispatch]
  );

  const WidgetContent = useMemo(() => {
    if (requesting) {
      return <div className="loading-state" />;
    }

    if (lastCreatedChat) {
      return <ChatContinueConversationState onOpenChat={handleChatOpen} chat={lastCreatedChat} />;
    }

    return <ChatWelcomeState onSubmit={handleStartConversation} />;
  }, [handleChatOpen, handleStartConversation, requesting, lastCreatedChat]);

  return <div className="chat-widget-wrapper">{WidgetContent}</div>;
};

export default ChatWidget;
