import React, { useState } from "react";

import PageWrapper from "WebVisual/PageWrapper";
import ClientCall from "WebVisual/Components/ClientCalls";
import ChatWidget from "WebVisual/Components/ChatWidget";
import { useGetLastChatInfo } from "WebVisual/Components/ChatWidget/hooks";

const ClientCalls = () => {
  const [search, setSearch] = useState("");

  const { lastCreatedChat, requesting } = useGetLastChatInfo();

  return (
    <PageWrapper
      onChange={setSearch}
      title="Client Calls"
      pageBodyHeader={
        lastCreatedChat ? <ChatWidget lastCreatedChat={lastCreatedChat} requesting={requesting} /> : undefined
      }
    >
      <ClientCall search={search} />
    </PageWrapper>
  );
};

export default ClientCalls;
